import { initializeApp } from 'firebase/app'
import { H } from 'highlight.run'
import isString from 'lodash/isString'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router'

import { initializeStripe } from 'features/stripe'
import './main.css'
import { getRouteObjects } from './routing'

// Initialize highlight.
if (isString(import.meta.env.VITE_HIGHLIGHT_PROJECT_ID)) {
  H.init(import.meta.env.VITE_HIGHLIGHT_PROJECT_ID, {
    serviceName: 'frontend-app',
    tracingOrigins: [
      'us-central1-roger-signer.cloudfunctions.net/api-internal'
    ],
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true
    },
    enableCanvasRecording: true,
    samplingStrategy: {
      canvas: 1,
      canvasMaxSnapshotDimension: 480
    }
  })
} else {
  console.debug('[app] highlight disabled')
}

// Initialize firebase.
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
}
console.debug(`[app] project ${JSON.stringify(firebaseConfig.projectId)}`)
initializeApp(firebaseConfig)

// Initialize stripe (on all pages so it can do anti-fraud).
const stripeKey = import.meta.env.VITE_STRIPE_KEY
initializeStripe(stripeKey)

// Create react router.
const router = createBrowserRouter(getRouteObjects())

// Render react tree to the DOM.
const root = document.createElement('div')
document.body.appendChild(root)
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
