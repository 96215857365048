import { ReactNode } from 'react'
import {
  ErrorResponse,
  isRouteErrorResponse,
  useRouteError
} from 'react-router'

import Logo from 'features/brand/Logo'
import Background from 'features/layout/Background'
import { HttpError } from 'features/errors'

export default function ErrorBoundary (): JSX.Element {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return <ResponseErrorScreen response={error} />
  }

  if (error instanceof HttpError) {
    return <ResponseErrorScreen response={error} />
  }

  if (error instanceof Error) {
    console.error(error, error.name, error.message, JSON.stringify(error))
    return (
      <ErrorScreen>
        <div className='space-y-4'>
          <div className='font-bold text-gray-700'>Error</div>
          <div className='text-4xl font-bold text-gray-900'>
            {error.message}
          </div>
        </div>
      </ErrorScreen>
    )
  }

  console.error(JSON.stringify(error))

  return (
    <ErrorScreen>
      <div className='space-y-4'>
        <div className='font-bold text-gray-700'>Unexpected error</div>
        <div className='text-4xl font-bold text-gray-900'>
          <pre>{JSON.stringify(error, null, '  ')}</pre>
        </div>
      </div>
    </ErrorScreen>
  )
}

function ErrorScreen ({ children }: { children: ReactNode }): JSX.Element {
  return (
    <Background>
      <div className='py-12 px-4 text-gray-950'>
        <div className='max-w-prose mx-auto'>
          <div className='space-y-8'>
            <a href='/'>
              <Logo />
            </a>
            {children}
            <div className='text-sm'>
              This error and its details have been reported to our team.
            </div>
            <div className='text-sm'>
              You can reach us at{' '}
              <a
                href='mailto:support@thanksroger.com'
                className='underline underline-offset-4 text-gray-600'
              >
                support@thanksroger.com
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </Background>
  )
}

function ResponseErrorScreen ({
  response
}: {
  response: ErrorResponse
}): JSX.Element {
  return (
    <ErrorScreen>
      <div className='space-y-4'>
        <div className='font-bold text-gray-700'>Error {response.status}</div>
        <div className='text-4xl font-bold text-gray-900'>
          {response.statusText}
        </div>
      </div>
      {response.data !== undefined && (
        <div className='space-y-2'>
          <div className='text-xs'>Error details:</div>
          <div className='rounded border border-gray-200 p-4 bg-gray-100'>
            <pre className='text-sm text-gray-600'>
              {JSON.stringify(response.data, null, '  ')}
            </pre>
          </div>
        </div>
      )}
    </ErrorScreen>
  )
}
